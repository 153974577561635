(function($){

$('#subscribe-newsletter').on('submit', function(event) {
  event.preventDefault();
  event.stopImmediatePropagation();
  var nName = $('#newsletter-name').val(),
    nEmail = $('#newsletter-email').val(),
    newsletterButton = $(this).find('button'),
    modalNewsletter = $('#modal-newsletter');

  // set newsletter button text
  newsletterButton.text('Sending Request...');

  $.ajax({
    url: brumbyObj.ajaxurl,
    type: 'POST',
    dataType: 'html',
    data: {
      action: 'subscribe',
      nonce: brumbyObj.nonce,
      name: nName,
      email: nEmail
    },
  })
  .done(function(respond) {
    respond = JSON.parse(respond);
    if ( respond.status == 'success' ) {
      $('#subscribe-newsletter input').val('');
      ga('send', 'event', 'newsletter', 'signup-success', 'Newsletter form signup');
    } else {
      ga('send', 'event', 'newsletter', 'signup-failed', 'Newsletter form signup');
    }
    
    modalNewsletter.find('.modal-body').html( respond.message );
    modalNewsletter.modal('show');
  })
  .always(function() {
    newsletterButton.text('Sign Up To Our Newsletter');
  });

});

})(jQuery);