(function( $ ){
  var $grid = $('#grid-section'),
    filterCat = '',
    filterMeal = '',
    filterTime = '',
    filterRange = '',
    productFilter = [],
    isotopeGrid,
    analyticsData = '',
    productFilterResult,
    timeoutID = 0;

  var filterFns = {

    catFilter: function(elem) {
      return filterCat ? $(elem).is( filterCat ) : true;

    },
    timeFilter: function(elem) {

      return filterTime ? $(elem).is( filterTime ) : true;

    },
    mealFilter: function(elem) {

      return filterMeal ? $(elem).is( filterMeal ) : true;

    },
    rangeFilter: function(elem) {
      return filterRange ? $(elem).is( filterRange ) : true;
    },
    productcatFilter: function(elem) {
      if (productFilter.length == 0) {
        return true;
      }
      productFilterResult = false;

      $.each(productFilter, function(index, val) {
        if ( $(elem).is('.' + val) ) {
          productFilterResult = true;
          return false;
        }
      });
      return productFilterResult;
    }

  };

  $grid.imagesLoaded( {
    // options...
    },
    function() {

      if( $grid.hasClass('grid-section-products') ) {
        isotopeGrid = $grid.isotope({
          itemSelector: '.grid-item',
          layoutMode: 'masonry',
          stamp: '.stamp',
          // transitionDuration: 0,
          filter: function() {

            var elem = $(this);
            return filterFns.productcatFilter(elem) && filterFns.rangeFilter(elem);
          },
          sortBy : 'original-order'
        });

      } else {
        isotopeGrid = $grid.isotope({
          itemSelector: '.grid-item',
          layoutMode: 'fitRows',
          // transitionDuration: 0,
          filter: function() {

            var elem = $(this);

            return filterFns.catFilter(elem) && filterFns.timeFilter(elem) && filterFns.mealFilter(elem);
          },
          sortBy : 'original-order'
        });
      }

      isotopeGrid.on( 'layoutComplete', function( event, laidOutItems ) {
        echo.render();
      });

      $('.grid-filter-no-result').addClass('active');
      $('.grid-section .stamp').addClass('active');
    }
  );

  // Product Filters

  if ($('.filter-recipe').length > 0) {
    $(window).load(function() {
      filterCat = '.' + $('#recipe-category').val();
      if (filterCat !== '.cat-all') {
        isotopeGrid.isotope();
      }
    });
  }

  // Recipe Filters
  $('#recipe-category').on('change', function(event) {
    event.preventDefault();
    filterCat = '.'+$(this).val();

    isotopeGrid.isotope();

    analyticsData = filterCat.replace('.cat-', '');
    ga('send', 'event', 'recipe_filter', 'filter_category', analyticsData);
  });

  $('.filter-wrap input[type="radio"]').click(function(){
    if ($(this).is(':checked')) {
      filterTime = '.' + $(this).val();

      isotopeGrid.isotope();

      analyticsData = filterTime.replace('.time-', '');
      ga('send', 'event', 'recipe_filter', 'filter_times_to_make', analyticsData);
    }
  });

  $('#recipe-meal').on('change', function(event) {
    event.preventDefault();
    filterMeal = '.'+$(this).val();

    isotopeGrid.isotope();

    analyticsData = filterMeal.replace('.meal-', '');
    ga('send', 'event', 'recipe_filter', 'filter_meal_time', analyticsData);
  });

  // Product Filters
  $('#product-filter-range').on('change', function(event) {
    event.preventDefault();
    filterRange = '.'+$(this).val();

    isotopeGrid.isotope();

    // analytics
    analyticsData = filterRange.replace('.range-', '');
    ga('send', 'event', 'product_filter', 'filter_range', analyticsData);
  });

  $('.product-filter-cat').on('click', function(event) {
    var $val = $(this).val();

    if ($(this).is(':checked')) {
      productFilter.push($val);
    } else {
      var i = productFilter.indexOf( $val );
      if(i != -1) {
        productFilter.splice(i, 1);
      }
    }

    isotopeGrid.isotope();
    ga('send', 'event', 'product_filter', 'filter_category', productFilter);
  });

  $(window).on('resize', function(event) {
    event.preventDefault();
    isotopeGrid.isotope();
    isotopeGrid.isotope('layout');
  });

  $grid.on( 'arrangeComplete', function( event, filteredItems ) {
    var stamp = $(this).find('.stamp');
    clearTimeout(timeoutID); 
      
    if ( filteredItems.length === 0 ) {
      $(this).addClass('zindex0');
    } else if( filteredItems.length === 1 ) {
      $('.featured-product').addClass('bigger-stamp');
      
      timeoutID = setTimeout(function() {
        // delay 500ms to prevent max exec size
        isotopeGrid.isotope();
        isotopeGrid.isotope('layout');
      }, 500);
        
        
      $(this).removeClass('zindex0');
    } else {
      $('.featured-product').removeClass('bigger-stamp');
        
      timeoutID = setTimeout(function() {
        // delay 500ms to prevent max exec size
        isotopeGrid.isotope();
        isotopeGrid.isotope('layout');
      }, 500);

      $(this).removeClass('zindex0');
    }
  });

})( jQuery );