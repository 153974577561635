/*
**  Custom functions
*/
(function($){

	stickyHeader();
  navbarToggleClose();
  searchFormToggle();

  recipeStickySidebar();
  shareSticky();
  
  hoverImageAndTheCaption( $('.grid-image a'), $('.grid-item'), $('.grid-title a') );
  hoverImageAndTheCaption( $('.featured-product-image a'), $('.featured-product'), $('.featured-product-title a') );
  hoverImageAndTheCaption( $('.about-sidebar-wrapper a'), $('.about-sidebar-container'), $('.sidebar-caption a') );

  addHoverStyleOnAboutSidebarImg();
  
  gridQuickInfo();

  filterToggle();
  filterSelectChosenInit();
  filterIsFiltered();
  
  homeHeroTextFadeIn();

  accorDionPanelToggleClass();
  scCarouselDotsAdjustment();

  $(window).load(function() {
    scCarouselDotsAdjustment();
    scImageContentVerticalAlign();
  });

  $(window).on('resize', function() {
    recipeStickySidebar();
    shareSticky();
    scImageContentVerticalAlign();
    scCarouselDotsAdjustment();
  });

	function stickyHeader() {
    if(!$('.navbar-row').length){
        return;
    }
    
    var navbarRow = $('.navbar-row'),
        navbarHead = $('.navbar-head');
    $(window).on('scroll', function () {
    	checkScroll();
    });

    checkScroll();

    function checkScroll() {
      if ($(document).scrollTop() > navbarRow.height() ) {
      	navbarHead.addClass('navbar-head-sticky');
      } else {
      	navbarHead.removeClass('navbar-head-sticky');
     	}
    }
	}

  function navbarToggleClose() {
    if(!$('.navbar-toggle-close').length){
        return;
    }

    var navbarToggleClose = $('.navbar-toggle-close'),
        navbarMenuCollapse = $('.navbar-menu-collapse'),
        searchFormMobile = $('#searchform-mobile');
    navbarToggleClose.on('click', function() {
      navbarMenuCollapse.collapse('hide');
      searchFormMobile.slideUp('400');
    });
  }

  function searchFormToggle() {
    if(!$('.search-form').length && !$('.menu-item-search').length){
        return;
    }

    var searchFormDesktop = $('#searchform-desktop'),
        searchFormMobile = $('#searchform-mobile'),
        searchToggleDesktop = $('.navbar-row .menu-item-search > a'),
        searchToggleMobile = $('.navbar-menu-collapse .menu-item-search > a');

    toggleSearchForm(searchToggleDesktop, searchFormDesktop);
    toggleSearchForm(searchToggleMobile, searchFormMobile);

    function toggleSearchForm(toggleElement, targetElement) {
      toggleElement.off('click').on('click', function() {
        if ( targetElement.is(':hidden') ) {
          targetElement.slideDown('400');
        } else {
          targetElement.slideUp('400');
        }

        return false;
      });
    }

    $('.search-form-overlay').on('click', function() {
      searchFormMobile.slideUp('400');
    });
  }

  function recipeStickySidebar() {
    if(!$('.recipe-sidebar-sticky').length){
        return;
    }

    var stickyEl          = $(".recipe-sidebar-sticky"),
      $w                  = $(window),
      navbarHeight        = $('.navbar-head').height() + 10,
      offset              = stickyEl.offset(),
      parentOffset        = $('.recipe-sidebar-parent').offset(),
      topPadding          = $('body').hasClass('admin-bar') ? 32 + navbarHeight : navbarHeight,
      bottomElement       = $('.more-wrapper').length ? $('.more-wrapper') : $('footer'),
      bottomElementOffset = bottomElement.offset(),
      bottomLimit         = bottomElementOffset.top - stickyEl.outerHeight() - topPadding,

      recipeContent       = $('.recipe-content-wrapper');

    // check content height
    if ( recipeContent.outerHeight() < $('.recipe-sidebar-parent').outerHeight() + stickyEl.outerHeight() ) {
      // disable sticky sidebar if content height less than sidebar height
      stickyEl.addClass('no-sticky');
      return;
    }

    if ( $w.width() > 991 ) {
      recipeDoScroll();
      $w.on('scroll', function(event) {
        recipeDoScroll();
      });
    }

    function recipeDoScroll() {
      if ( $w.scrollTop() > offset.top && $w.scrollTop() < bottomLimit ) {
        stickyEl.css('top', $w.scrollTop() - parentOffset.top + topPadding);
      } else if ( $w.scrollTop() > bottomLimit ) {
        stickyEl.css('top', bottomLimit - stickyEl.outerHeight() - topPadding);
      } else {
        stickyEl.removeAttr('style');
      }
    }
  }

  function shareSticky() {
    if ($('.vertical-share').length === 0) {
      return;
    }

    var stickyEl          = $('.vertical-share'),
      $w                  = $(window),
      navbarHeight        = $('.navbar-head').height() + 10,
      offset              = stickyEl.offset(),
      parentOffset        = $('.content-parent').offset(),
      topPadding          = $('body').hasClass('admin-bar') ? 32 + navbarHeight : navbarHeight,
      bottomElement       = $('.more-stories').length ? $('.more-stories') : $('footer'),
      bottomElementOffset = bottomElement.offset(),
      bottomLimit         = bottomElementOffset.top - stickyEl.outerHeight() - topPadding;

    if ( $w.width() > 991 ) {
      recipeShareScroll();
      $w.on('scroll', function(event) {
        recipeShareScroll();
      });
    }

    function recipeShareScroll() {
      if ( $w.scrollTop() > offset.top - navbarHeight && $w.scrollTop() < bottomLimit ) {
        stickyEl.css('top', $w.scrollTop() - parentOffset.top + topPadding );
      } else if ( $w.scrollTop() > bottomLimit ) {
      //   stickyEl.css('top', bottomLimit - stickyEl.outerHeight() - topPadding);
      } else {
        stickyEl.removeAttr('style');
      }
    }

  }

  function hoverImageAndTheCaption(imageEl, parentEl, captionEl) {
    if(!imageEl.length && !captionEl.length && !parentEl.length){
        return;
    }

    imageEl.on('mouseenter mouseleave', function(e) {
      $(this).closest(parentEl).find(captionEl).toggleClass('pseudo-hover');
    });
  }

  function gridQuickInfo() {
    if(!$('.grid-excerpt').length){
      return;
    }

    $('.grid-excerpt-btn').on('click', function(e) {
      e.preventDefault();
      var closestWrapper = $(this).closest('.grid-excerpt');
      closestWrapper.toggleClass('grid-excerpt-reveal');
      $(this).toggleClass('grid-excerpt-btn-top');
    });
  }

  function filterToggle() {
    var filterToggle = $('.filter-toggle'),
        filterForm = $('.filter-form'),
        filterToggleOffset = filterToggle.offset();

    if(!filterToggle.length && !filterForm.length){
      return;
    }

    filterToggle.on('click', function() {
      $(this).toggleClass('filter-toggle-open');
      $(this).siblings('.filter-form').slideToggle().toggleClass('filter-form-open');
      $(this).closest('.filter-container').toggleClass('filter-container-open');
    });
  }

  function filterSelectChosenInit() {
    if(!$('.filter-form select').length){
      return;
    }
    $('.filter-form select').chosen();
    $('.filter-form').slideUp();
  }

  function filterIsFiltered() {
    if(!$('.filter-form').length){
      return;
    }

    var filterFiltered = [],
        filterFormFiltered = $('.filter-form-filtered');

    $('.filter-form input[type="checkbox"]').on('change', function() {
      if ( $(this).is(":checked") ) {
        filterFiltered.push('1');
        $(this).parent().clone().appendTo('.filter-form-filtered-checkbox').attr('id', $(this).attr('id')+'-parent');
      } else {
        filterFiltered.pop();
        $('#'+$(this).attr('id')+'-parent').remove();
      }
      filterContainerAddRemoveClass( $(this).closest('.filter-container') );
    });

    $('.filter-form input[type="radio"]').on('change', function() {
      var thisID = $(this).attr('id'),
          theLabel = $(this).closest('.filter-form-group-radio').find('.filter-label').clone().html(),
          theSelected = $(this).next('label'),
          theIndex = $(this).index() + 1;

      if ( $(this).val() !== 'time-all' ) {
        filterFiltered.push('1');
        $('.filtered-radio').remove();
        $('.filter-form-filtered-radio')
          .append('<div id="filtered-radio-'+ thisID +'" class="filtered-radio" data-src-id="'+thisID+'">'+theLabel+'<span class="filtered-radio-text">0 - '+theSelected.text()+'<span class="filtered-radio-close"></span></span></div>');
      } else {
        filterFiltered.pop();
        $('.filtered-radio').remove();
      }
      filterContainerAddRemoveClass( $(this).closest('.filter-container') );

      $('.filter-recipe .filter-radio-wrapper > *').each(function(index, el) {
        if ( index <= theIndex ) {
          $(el).addClass('active');
        } else {
          $(el).removeClass('active');
        }
      });
    });

    $('.filter-form select').on('change', function() {
      var theLabel = $(this).siblings('.filter-label').clone().html(),
          theSelected = $(this).find('option:selected'),
          thisID = $(this).attr('id');
      
      if ( theSelected.val().indexOf('-all') !== -1) {
        filterFiltered.pop();
        $('#filtered-select-'+thisID).remove();
      } else {
        filterFiltered.push('1');
        $('.filter-form-filtered-select[data-wrapper-src-id="'+thisID+'"] .filtered-select').remove();
        $('.filter-form-filtered-select[data-wrapper-src-id="'+thisID+'"]')
          .append('<div id="filtered-select-'+ thisID +'" class="filtered-select" data-src-id="'+thisID+'">'+theLabel+'<span class="filtered-select-text">'+theSelected.text()+'<span class="filtered-select-close"></span></span></div>');
      }
      filterContainerAddRemoveClass( $(this).closest('.filter-container') );
    });

    $(document).on('click', '.filtered-select-close', function() {
      var thisParent = $(this).closest('.filtered-select'),
          srcID = thisParent.attr('data-src-id'),
          firstOption = $('#'+srcID).find('option:eq(0)').val();
      
      $('#'+srcID).val(firstOption).trigger('change').trigger('chosen:updated');
      thisParent.remove();
    });
    $(document).on('click', '.filtered-radio-close', function() {
      var thisParent = $(this).closest('.filtered-radio');
      
      $('input[type="radio"][name="filter-time[]"]').filter('[value="time-all"]').prop("checked",true).trigger('click');
      filterFiltered.pop();
      filterContainerAddRemoveClass( $(this).closest('.filter-container') );
      thisParent.remove();
    });

    function filterContainerAddRemoveClass(theElement) {
      if ( filterFiltered.length > 0) {
        theElement.addClass('filter-container-filtered');
      } else {
        theElement.removeClass('filter-container-filtered');
      }
    }
  }

  function homeHeroTextFadeIn() {
    if(!$('.hero-text-homepage').length){
      return;
    }

    setTimeout( function() {
      $('.hero-text-homepage').addClass('visible');
    }, 1000);
  }

  function accorDionPanelToggleClass() {
    if(!$('.panel-collapse').length){
      return;
    }

    var panelCollapse = $('.panel-collapse');
    panelCollapse.on('show.bs.collapse', function () {
      $(this).closest('.panel').addClass('in');
    });
    panelCollapse.on('hide.bs.collapse', function () {
      $(this).closest('.panel').removeClass('in');
    });
  }

  function scImageContentVerticalAlign() {
    if(!$('.sc-image-content-content').length){
      return;
    }
    var wWidth = $(window).width(),
        $scImgContentContent = $('.sc-image-content-content');
    
    $scImgContentContent.attr('style', '');
    
    if ( wWidth >= 768 ) {
      $scImgContentContent.each(function() {
        var thisHeight = $(this).height(),
            thisParentHeight = $(this).closest('.row').height();

        if (thisParentHeight > thisHeight) {
          $(this).css('padding-top', Math.floor((thisParentHeight - thisHeight)/2) );
        }
      });
    }
  }

  function scCarouselDotsAdjustment() {
    if(!$('.sc-carousel').length){
      return;
    }
    var scCarousel = $('.sc-carousel'),
        theActiveSlideMediaInit = scCarousel.find('.slick-active .carousel-media'),
        theDotsInit = scCarousel.find('.slick-dots'),
        theArrowsInit = scCarousel.find('.slick-arrow');

    theDotsInit.css({'bottom': 'auto', 'top': theActiveSlideMediaInit.height()});
    theArrowsInit.css('top', Math.floor(theActiveSlideMediaInit.height()/2));

    scCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      var theDotsBefore = $(this).find('.slick-dots');
      theDotsBefore.css('opacity', '0');
    });
    scCarousel.on('afterChange', function(event, slick, currentSlide, nextSlide){
      var theActiveSlideMediaAfter = $(this).find('.slick-active .carousel-media'),
          theDotsAfter = $(this).find('.slick-dots'),
          theArrowsAfter = $(this).find('.slick-arrow');
      theDotsAfter.css({'bottom': 'auto', 'top': theActiveSlideMediaAfter.height()});
      theDotsAfter.css('opacity', '1');
      theArrowsAfter.css('top', Math.floor(theActiveSlideMediaAfter.height()/2));
    });
  }

  function addHoverStyleOnAboutSidebarImg() {
    if(!$('.about-sidebar-wrapper').length){
      return;
    }

    $('.about-sidebar-wrapper a').each(function() {
      $(this).addClass('hover-image');
    });
  }

})(jQuery);


(function( $ ){

   $.fn.placeHolder = function() {  
      var input = this;
      var text = input.attr('placeholder');  // make sure you have your placeholder attributes completed for each input field
      if (text) input.val(text).css({ color:'grey' });
      input.focus(function(){  
         if (input.val() === text) input.css({ color:'lightGrey' }).selectRange(0,0).one('keydown', function(){     
            input.val("").css({ color:'black' });  
         });
      });
      input.blur(function(){ 
         if (input.val() === "" || input.val() === text) input.val(text).css({ color:'grey' }); 
      }); 
      input.keyup(function(){ 
        if (input.val() === "") input.val(text).css({ color:'lightGrey' }).selectRange(0,0).one('keydown', function(){
            input.val("").css({ color:'black' });
        });               
      });
      input.mouseup(function(){
        if (input.val() === text) input.selectRange(0,0); 
      });   
   };

   $.fn.selectRange = function(start, end) {
      return this.each(function() {
        if (this.setSelectionRange) { this.setSelectionRange(start, end);
        } else if (this.createTextRange) {
            var range = this.createTextRange();
            range.collapse(true); 
            range.moveEnd('character', end); 
            range.moveStart('character', start); 
            range.select(); 
        }
      });
   };

  var placeholder = 'placeholder' in document.createElement('input');  
  if (!placeholder) {      
        $(":input").each(function(){   // this will work for all input fields
          $(this).placeHolder();
        });
  }

})( jQuery );