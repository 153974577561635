var mapObj,
    geocoder,
    markers = [],
    markerCluster,
    locationDistance = 1000,
    msgEl = $('.map-message'),
    defaultLat = -25.528816,
    defaultLng = 134.780592,
    currCenter,
    mapOpt = {
      center: {lat: defaultLat, lng: defaultLng},
      zoom: 5,
      mapTypeControl: false,
      scaleControl: false,
      streetViewControl: true,
      scrollwheel: false,
      styles: [{"featureType":"landscape","elementType":"all","stylers":[{"hue":"#ffbb00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]},{"featureType":"road.highway","elementType":"all","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","elementType":"all","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]}]
    },
    autocompleteOpt = {
      componentRestrictions: {
        country: 'AU',
      }
    },
    map = {
      isNumeric: function( obj ) {
        return (obj - 0) == obj && (''+obj).trim().length > 0;
      },
      showError: function() {
        $('.form-map-search').addClass('search-error');
        msgEl.text("Incorrect location entered");
        setTimeout(function() {
          msgEl.html('<span class="current-location">or use your current location</span>');
          $('.form-map-search').removeClass('search-error');
        }, 3000);
      },
      init: function () {
        mapObj = new google.maps.Map(document.getElementById('map'), mapOpt);

        locationDistance = parseInt(storesObj.radius);
        if (storesObj.radius === '0') {
          locationDistance = 50;
        }

        var acInput = document.getElementById('postcode');

        $('body').on('click', '.current-location', function(event) {
          event.preventDefault();
          if (navigator.geolocation) {
            var timeoutVal = 10 * 1000 * 1000;
            navigator.geolocation.getCurrentPosition(
              function (position) {
                var userlocation = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                // clear marker
                map.deleteMarker();
                map.filterList(userlocation, 'latitude');
              },
              function (error) {
                alert("failed : " + error.message);
              },
              {enableHighAccuracy: true, timeout: timeoutVal, maximumAge: 0}
            );
          } else {
            alert("Geolocation is not supported by this browser");
          }
        });

        // set autocomplete
        var autocomplete = new google.maps.places.Autocomplete( acInput, autocompleteOpt );
        autocomplete.bindTo('bounds', mapObj);

        $('#search-store').unbind('click').on('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
          var query = $('#postcode').val();

          if ( query === '' ) {
            map.deleteMarker();
            map.clearList();
            $('.map-container').removeClass('filtered');
            mapObj.setOptions(mapOpt);
          } else {
            if ( map.isNumeric( query ) ) {
              map.filterList(query, 'postal-code');
            } else {
              map.filterList(query, 'suburb');
            }
            
          }
        });
      },
      deleteMarker: function () {
        _.each(markers, function (value, key) {
          markers[key].setMap(null);
        });
        markers = [];

        if (typeof markerCluster !== 'undefined') {
          markerCluster.clearMarkers();
        }
      },
      clearList: function () {
        $('#map-result .result-list').html('');
      },
      focusList: function (id) {
          if (typeof id == 'undefined') return false;

          var listEl = $('.result-list');

          listEl.children('.list-item').removeClass('focus');
          listEl.scrollTop(listEl.scrollTop() + $('#' + id).position().top);
          // add class focus
          $('#' + id).addClass('focus');
      },
      showNearest: function (position, type) {
        if (typeof type == 'undefined') type = 'postal-code';

        var latLng,
            $param,
            distance,
            newData = [],
            tempMarkerData = JSON.parse(storesObj.stores); // set temporary store data

        if (type == 'postal-code') {
            $param = {
                componentRestrictions: {
                    country: 'AU',
                    postalCode: position
                }
            };
        } else if (type == 'suburb') {
            $param = {
                'address': position,
                country: 'AU',
            };
        } else {
            $param = {
                location: position
            };
        }

        // get location by postcode
        geocoder = new google.maps.Geocoder();
        geocoder.geocode($param, function (results, status) {
          if (status == google.maps.GeocoderStatus.OK) {
            msgEl.removeClass('visible');
            // set center
            mapObj.panTo(results[0].geometry.location);

            // add marker
            if (type == 'latitude') {
              marker = new google.maps.Marker({
                position: results[0].geometry.location,
                map: mapObj,
                icon: storesObj.locationMarker,
                title: 'Your Location'
              });
            }

            $('.map-container').addClass('filtered');
            google.maps.event.trigger(mapObj, "resize"); 

            _.each(tempMarkerData, function(store, i){
              latLng = new google.maps.LatLng(parseFloat(store.address.lat),parseFloat(store.address.lng));
              // calculate distance
              distance = google.maps.geometry.spherical.computeDistanceBetween(results[0].geometry.location, latLng);
              distance = (distance / 1000).toFixed(0);

              if (distance < locationDistance) {
                  store.distance = parseFloat(distance);
                  newData.push(store);
              }
            });

            // sort by distance
            newData = _.sortBy(newData, function(obj){ return +obj.distance; });

            // set Map
            map.setMarker(newData);
            
          } else {
            console.log("Geocode was not successful for the following reason: " + status);
            if ( !msgEl.hasClass('visible') ) {
              msgEl.addClass('visible');
            }

            map.clearList();

            map.showError();
          }
        });
      },
      setMarker: function (markerArray) {
        if (typeof markerArray == 'undefined' || !markerArray) return false;

        map.deleteMarker();

        var i,
            stores,
            bounds = new google.maps.LatLngBounds(),
            marker,
            latLng,
            html,
            markerNum,
            listEl = $('#map-result .result-list');

        if (_.isObject(markerArray)) {
          stores = markerArray;
        } else {
          stores = JSON.parse(markerArray);
        }
        // infowindow = new google.maps.InfoWindow()
        map.clearList();
        if (stores.length > 0) {
          _.each(stores, function (store, key) {
              latLng = new google.maps.LatLng(parseFloat(store.address.lat), parseFloat(store.address.lng));
              marker = new google.maps.Marker({
                  position: latLng,
                  map: mapObj,
                  icon: storesObj.marker,
                  title: store.name
              });

              // add event listener
              google.maps.event.addListener(marker, 'click', (function (marker, i){
                return function () {
                  window.location = store.permalink;                
                };
              })(marker, i));

              // add hover eventlistener
              google.maps.event.addListener(marker, 'mouseover', (function (marker, i){
                return function () {
                  marker.setIcon(storesObj.markerActive);
                  map.focusList('item-' + key);
                };
              })(marker, i));

              google.maps.event.addListener(marker, 'mouseout', (function (marker, i){
                return function () {
                  marker.setIcon(storesObj.marker);
                  $('.result-list .list-item').removeClass('focus');
                };
              })(marker, i));

              markers.push(marker);
              bounds.extend(latLng);

              markerNum = markers.length - 1;

              // set store list
              html = '<div class="list-item" onmouseover="markers[' + markerNum + '].setIcon(storesObj.markerActive);" onmouseout="markers[' + markerNum + '].setIcon(storesObj.marker);" id="item-' + key + '" data-key="' + key + '">' +
                '<h3><a href="' + store.permalink + '">' + store.name + '</a></h3>' +
                '<p>' + store.address.address + '</p>' +
                '<p>' + store.phone + '</p>' +
                '<a href="' + store.permalink + '">View store details</a>' +
                '</div>';
              listEl.append(html);

          });
        } else {
          // set store list
          html = '<div class="list-item">' +
            '<h3>No Result...</h3>' +
            '</div>';
          listEl.append(html);
        }

        markerCluster = new MarkerClusterer(mapObj, markers, {
          imagePath : storesObj.clusterPath
        });

        // fit bound
        if (!_.isEmpty(stores[0])) {
          mapObj.fitBounds(bounds);
        } else {
          mapObj.setOptions({ zoom: 5 });
        }
        currCenter = mapObj.getCenter();
        mapObj.setCenter(currCenter);
        google.maps.event.trigger(mapObj, 'resize');
      },
      filterList: function (query, type) {
        if (typeof query === 'undefined' || query === '') return false;

        if (typeof type === 'undefined' || type === '') {
          type = 'postal-code';
        }
        // remove marker
        map.deleteMarker();
        
        map.showNearest(query, type);

        if ( $(window).outerWidth() < 992 ) {
          mapObj.setOptions({draggable: false});
        }
        // google.maps.event.trigger(mapObj, 'resize');
      }
    };

if ( $('#map').length > 0 ) {
  $(window).load(function() {
    map.init();
  });

  $(window).on('resize', function(event) {
    if ( $(window).outerWidth() < 992 ) {
      mapObj.setOptions({draggable: false});
    }

    currCenter = mapObj.getCenter();
    google.maps.event.trigger(mapObj, 'resize');
    mapObj.setCenter(currCenter);
  });

  $('#postcode').on('keyup', function(event) {
    if ( $(this).val() === '' ) {
      msgEl.html('<span class="current-location">or use your current location</span>');
    }

    if (event.keyCode == 13) {
      $('#search-store').trigger('click');
    }

    $('.form-map-search').removeClass('search-error');
    msgEl.html('<span class="current-location">or use your current location</span>');
  });
}