/*
**  Global initialisation of things
*/
(function($){

  echo.init({
    offset: 100,
    throttle: 250,
    unload: false,
    callback: function (element, op) {
      $(element).parent().removeClass('loading-image');
      $(element).closest('.grid-section').removeClass('grid-loading');
      setTimeout(function() {
        $(element).parent().addClass('hover-image');
      }, 500);
    }
  });

  if ($('#home-map').length > 0) {
    var mapEl = $('#home-map'),
      lat = parseFloat(mapEl.attr('data-lat')),
      lng = parseFloat(mapEl.attr('data-lng'));

    new google.maps.Map(document.getElementById('home-map'), {
      center: {lat: lat, lng: lng},
      disableDefaultUI: true,
      zoom: 10,
      draggable: false,
      scrollwheel: false,
      styles: [{"featureType":"landscape","elementType":"all","stylers":[{"hue":"#ffbb00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]},{"featureType":"road.highway","elementType":"all","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","elementType":"all","stylers":[{"hue":"#0078FF"},{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1}]}]
    });
  }

  if ($('#stories-filter').length > 0) {
    $('#stories-filter').on('change', '#stories-sort', function(event) {
      event.preventDefault();
      $('#stories-filter').submit();
    });

    $('#stories-category').on('change', function(event) {
      event.preventDefault();
      var url = $(this).val();

      var analyticsData = $(this).find('option:selected').text();
      ga('send', 'event', 'stories_filter', 'stories_category', analyticsData);

      window.location = url;
    });
  }

    // parallax
    /*
    if ($('.hero').length > 0) {
        if ($(window).outerWidth() > 992) {
            $.stellar();
        }
    }
    */
    if ($('.hero').length > 0) {
        $(window).scroll(function(){
            var posB = 30;
            if ($(window).outerWidth()<=767) posB = 20;
            
            var tmp  = $('.hero-text').css('bottom');
            var scrT = $(window).scrollTop();
            var newB = posB + (parseInt(scrT)/2);
            $('.hero-text').css('bottom', newB+'px');
        });   
    }
    

  $('img').removeAttr('width').removeAttr('height');

  if ($('.recipe-share').length > 0) {
    $('.share-facebook').on('click', function(event) {
      ga('send', 'event', 'social_share', 'facebook', 'Social share');
    });

    $('.share-twitter').on('click', function(event) {
      ga('send', 'event', 'social_share', 'twitter', 'Social share');
    });
    
    $('.share-email').on('click', function(event) {
      ga('send', 'event', 'social_share', 'email', 'Social share');
    });
  }

})(jQuery);