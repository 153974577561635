/*
**  For slick slide
*/
(function($){


    // Slick Multi Item slider
    if ( $('.slider.slider-multi').length > 0 ) {

        var $mslider = $('.slider.slider-multi');

        $mslider.on('breakpoint', function(e, slick, breakpoint) {
            // Reset position on breakpoint since sometimes the layout goes whack
            // based on the layout triggers
            positionArrows();

        });

        $mslider.slick({
            infinite: true,
            dots: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            responsive: [
                {
                    breakpoint: 768,
                        settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2
                    }
                },
                {
                    breakpoint: 480,
                        settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        arrows: false
                    }
                }
            ]
        });

        $mslider.show();

        // Adjust the arrows for "smaller" screens
        // Default, add empty li to the dots
        var positionArrows = function() {

            var w           = $(window).width(),
                $slick      = $('.slick-list', $mslider),
                $larr       = $('.slick-prev', $mslider),
                $rarr       = $('.slick-next', $mslider),
                $slickdot   = $('.slick-dots', $mslider),
                applied     = false;

            if ( w < 1310 && applied === false) {

                $slickdot.before($larr);
                $slickdot.after($rarr);

                applied = true;

            } else if ( w >= 1310 && applied === true ) {

                $slick.before($larr);
                $slick.after($rarr);

                applied = false;

            }

        };

        positionArrows();

        $(window).resize(function(){

            positionArrows();

        });


    }

    if ($('.sc-carousel').length > 0) {
        $('.sc-carousel').slick({
            infinite: true,
            dots: true,
            adaptiveHeight: true
        });
    }

    if ($('.featured-slides').length > 0) {
        var currentSlide = $('.featured-slides').slick({
            infinite: true,
            dots: false,
            adaptiveHeight: true,
            fade: true,
            cssEase: 'linear'
        });
        
        $('.featured-slides .dots li').on('click', function(event) {
            event.preventDefault();
            var index = $(this).attr('data-key');
            currentSlide.slick( 'slickGoTo', index );
        });
    }

})(jQuery);