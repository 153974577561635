// https://developers.google.com/youtube/iframe_api_reference

var player = [];

function onYouTubePlayerAPIReady() {
  var players = document.getElementsByClassName('sc-video-iframe');
  Array.prototype.forEach.call(players, function(el, index, array) {
  	player[index] = new YT.Player(el.getAttribute("id"), {
	    events: {
	      'onReady': onPlayerReady
	    }
	  });
  });
}

function onPlayerReady(event) {
	var playButtons = document.getElementsByClassName("icon-play");
	Array.prototype.forEach.call(playButtons, function(el, index, array) {
		el.addEventListener("click", function() {
	    this.className += " hide"; 
	    this.parentNode.className += " hide";
	    player[index].playVideo();
	  });
	});
  
}

// Inject YouTube API script
var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);